import { CommonModule, registerLocaleData } from '@angular/common';
import { Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { IgxGridModule, IgxSimpleComboComponent, IgxDatePickerComponent, IgxButtonDirective, IgxInputGroupModule, IgxIconModule, IgxExcelExporterOptions, IgxExcelExporterService, IgxGridComponent, IGridCellEventArgs, IRowSelectionEventArgs, IgxDialogModule, IgxDialogComponent } from '@infragistics/igniteui-angular';
import { DeviceService } from 'src/app/services/device.service';
import { GridFilterInputComponent } from "../../../grid-filter-input/grid-filter-input.component";
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import localeNb from '@angular/common/locales/nb';
import { formatDate } from 'src/app/util/FormatDate';
import { DatalexClient, IMoneyLaunderingListBE, IMoneyLaunderingTypeBE, IUserLimitedBE, UserAreaEnum } from '@datalex-software-as/datalex-client';
import { MoneyLaunderingRiskType } from 'src/app/util/MoneyLaunderingRiskType';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { Subscription } from 'rxjs';
import { ButtonRowButtonComponent } from "../../../button-row-button/button-row-button.component";
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { Router } from '@angular/router';
import { ActivityLogService } from '../../../activity-log-overlay/activity-log.service';
import newActivity from 'src/app/classes/Activity/Actvity';
import { MoneyLaunderingFormComponent } from '../../../money-laundering-form/money-laundering-form.component';

interface IMoneyLaunderingStatusForm {
  clientResponsible: FormControl<string | null>;
  caseResponsible: FormControl<string | null>;
  startDate: FormControl<Date | null>;
  endDate: FormControl<Date | null>;
  riskEvaluation: FormControl<number | null>;
  controlType: FormControl<string | null>;
}

registerLocaleData(localeNb);

@Component({
  selector: 'app-money-laundering-status',
  templateUrl: './money-laundering-status.component.html',
  styleUrls: ['./money-laundering-status.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IgxGridModule,
    IgxSimpleComboComponent,
    IgxDatePickerComponent,
    IgxButtonDirective,
    IgxInputGroupModule,
    GridFilterInputComponent,
    IgxIconModule,
    ButtonRowButtonComponent, IgxDialogModule,
    MoneyLaunderingFormComponent,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'nb-NO' }]
})
export class MoneyLaunderingStatusComponent implements OnInit {

  @ViewChild('grid', { static: true }) public grid!: IgxGridComponent;

  @ViewChild('moneyLaunderingDialog', { static: true }) public moneyLaunderingDialog!: IgxDialogComponent;
  @ViewChild('moneylaunderingForm') form!: MoneyLaunderingFormComponent;

  selectedCaseContact: string | null = null;

  selectedCase: any = null;
  selectedRowData: any | null = null;
  caseRights!: string;

  isMobile = false;
  isDesktop = true;
  isLoading = true;

  filterForm = new FormGroup<IMoneyLaunderingStatusForm>({
    clientResponsible: new FormControl<string | null>(null),
    caseResponsible: new FormControl<string | null>(this.sys.loggedinUserContact?.Id || null),
    startDate: new FormControl<Date | null>(new Date(new Date().getFullYear(), 0, 1), Validators.required),
    endDate: new FormControl<Date | null>(new Date(), Validators.required),
    riskEvaluation: new FormControl<number | null>(-1, Validators.required),
    controlType: new FormControl<string | null>("null", Validators.required),
  }, { validators: this.dateRangeValidator });

  cases: IMoneyLaunderingListBE[] = [];
  employees!: IUserLimitedBE[];
  riskEvaluationOptions = MoneyLaunderingRiskType;

  controlTypeOptions: IMoneyLaunderingTypeBE[] = [
    {
      Id: 'null',
      Code: '',
      Name: 'Alle',
      DeleteAllowed: false,
      CreatedBy: null,
      CreatedDate: '',
      ChangedBy: null,
      ChangedDate: '',
      Timestamp: [1],
      IsDeleted: false,
      IsNew: false,
    }
  ];

  subscriptions: Subscription = new Subscription();

  constructor(
    public deviceService: DeviceService,
    public sys: SystemCacheService,
    public combo: ComboSelectFunctionsService,
    private dlxClient: DatalexClient,
    private excelExporterService: IgxExcelExporterService,
    private screenSize: ScreenSizeService,
    private rights: UserRightsProviderService,
    private router: Router,
    private als: ActivityLogService
  ) { }

  ngOnInit(): void {
    this.dlxClient.NewMoneyLaundering().subscribe({
      next: res => {
        console.log('NewMoneyLaundering:', res);
      }
    });
    this.subscriptions.add(
      this.screenSize.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptions.add(
      this.screenSize.isDesktop$.subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      })
    );

    this.caseRights = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName;

    if (this.sys.sysReady) {
      this.initializeForm();
      this.loadCaseData();
    } else {
      this.sys.isReady.subscribe({
        next: () => {
          this.initializeForm();
          this.loadCaseData();
        }
      });
    }
  }

  private initializeForm(): void {
    this.loadFormOptions();
  }

  private loadFormOptions(): void {
    if (!this.sys.sysReady) {
      throw new Error('Employees not loaded');
    }

    this.dlxClient.GetMoneyLaunderingTypeAll().subscribe({
      next: (res) => {
        this.controlTypeOptions = [...this.controlTypeOptions, ...res];
      }
    });

    if (!this.filterForm.controls.caseResponsible.value) {
      this.filterForm.controls.caseResponsible.setValue(this.sys.loggedinUserContact?.Id);
    }

    this.employees = this.sys.allEmployees;
  }

  private loadCaseData(): void {
    this.isLoading = true;

    const clientResponsible = this.filterForm.controls.clientResponsible.value;
    const caseResponsible = this.filterForm.controls.caseResponsible.value;
    const startDate = this.filterForm.controls.startDate.value;
    const endDate = this.filterForm.controls.endDate.value;
    let controlType = this.filterForm.controls.controlType.value;
    let riskEvaluation = this.filterForm.controls.riskEvaluation.value ?? -1;

    controlType = controlType === 'null' ? null : controlType;

    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;

    this.dlxClient.MoneyLaunderingList(
      clientResponsible,
      caseResponsible,
      formattedStartDate,
      formattedEndDate,
      riskEvaluation,
      controlType
    ).subscribe({
      next: (response) => {
        this.cases = response;
        this.isLoading = false;
        console.log('Initial cases loaded:', response);
      },
      error: (err) => {
        console.error('Error loading initial cases:', err);
        this.isLoading = false;
      },
      complete: () => {
        console.log('Initial data load complete.');
        this.isLoading = false;
      }
    });
  }

  onSubmit(): void {
    if (this.filterForm.valid) {
      this.isLoading = true;

      const clientResponsible = this.filterForm.controls.clientResponsible.value || null;
      const caseResponsible = this.filterForm.controls.caseResponsible.value || null;
      const startDate = this.filterForm.controls.startDate.value || null;
      const endDate = this.filterForm.controls.endDate.value || null;
      let controlType = this.filterForm.controls.controlType.value || null;
      let riskEvaluation = this.filterForm.controls.riskEvaluation.value ?? -1;

      controlType = controlType === 'null' ? null : controlType;

      const formattedStartDate = startDate ? formatDate(startDate) : null;
      const formattedEndDate = endDate ? formatDate(endDate) : null;

      this.dlxClient.MoneyLaunderingList(
        clientResponsible,
        caseResponsible,
        formattedStartDate,
        formattedEndDate,
        riskEvaluation,
        controlType
      ).subscribe({
        next: (response) => {
          this.cases = response;
          this.isLoading = false;
          console.log('Cases updated:', response);
        },
        error: (err) => {
          console.error('Error updating cases:', err);
          this.isLoading = false;
        },
        complete: () => {
          console.log('Data update complete.');
          this.isLoading = false;
        }
      });
    } else {
      console.log('Form is invalid');
      this.filterForm.markAllAsTouched();
    }
  }

  // private formatDate(date: Date): string {
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = date.getFullYear();
  //   return `${year}-${month}-${day}`;
  // }

  private dateRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const group = control as FormGroup;
    const startDate = group.get('startDate')?.value;
    const endDate = group.get('endDate')?.value;
    return startDate && endDate && endDate < startDate ? { 'dateRangeInvalid': true } : null;
  }



  onExport(): void {
    const options = new IgxExcelExporterOptions("MoneyLaunderingData");
    this.excelExporterService.export(this.grid, options);
  }


  onClear(): void {
    // Handle clear filter logic
  }

  handleRowSelection(event: IRowSelectionEventArgs): void {
    console.log('Row selection changed:', event);
    this.selectedCase = event.added[0];
    console.log('Selected case:', this.selectedCase);

    this.selectedCaseContact = this.selectedCase?.CaseContactId;
    console.log('selectedCaseContact:', this.selectedCaseContact);

  }

  dateFormatter = (value: string | Date): string => {
    if (value) {
      const date = new Date(value);
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    }
    return value as string;
  };

  onPrint(): void {
    window.print()

  }

  printSection() {
    // Access the grid's columns to get the headers and field names
    const columns = this.grid.columns;

    const customHeaders: { [key: string]: string } = {
      'CaseNumber': 'S.nr',
      'ContactNumber': 'K.nr'
    };

    const headers = columns.map(col => customHeaders[col.field] || col.header);
    const fieldNames = columns.map(col => col.field);

    // Generate the table HTML
    let table = '<table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-collapse: collapse;">';
    let tableEnd = '</table>';

    // Generate table headers
    table += '<thead><tr>';
    headers.forEach(header => {
      table += `<th style="background-color: #4CAF50; color: white; padding: 10px;">${header}</th>`;
    });
    table += '</tr></thead>';

    // Generate table rows
    table += '<tbody>';
    this.grid.filteredSortedData.forEach((row: any, index: number) => {
      // Apply background color to the row
      const rowColor = index % 2 === 0 ? '#e7f4e4' : '#ffffff'; // Alternate between light green and white
      table += `<tr style="background-color: ${rowColor};">`;

      fieldNames.forEach(field => {
        let cellValue = row[field] !== null && row[field] !== undefined ? row[field] : '';

        if (field === 'RiskType') {
          cellValue = this.riskTypeFormatter(cellValue);
        }
        if (field === 'StartedDate') {
          cellValue = this.dateFormatter(cellValue);
        }

        table += `<td style="padding: 8px;">${cellValue}</td>`;
      });
      table += '</tr>';
    });
    table += '</tbody>';
    table += tableEnd;

    const style = `

    html, body {
      font-family: Arial, sans-serif;
      margin: 0px;
      padding: 0px;

    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
    th {
      background-color: #4CAF50;
      color: white;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr:nth-child(odd) {
      background-color: #ffffff;
    }
  `;

    const printFrame = document.getElementById('printFrame') as HTMLIFrameElement;
    if (printFrame.contentWindow) {
      const printDoc = printFrame.contentWindow.document;
      printDoc.open();
      printDoc.write(`
      <html>
      <head>
      <title>Print Section</title>
      <style>${style}</style>
      </head>
      <body>
        <h2>Status Hvitvasking</h2> 
        ${table}
      </body>
    </html>`);
      printDoc.close();
      printFrame.contentWindow.focus();
      printFrame.contentWindow.print();
    }
  }


  riskTypeFormatter = (value: number): string => {
    const riskType = MoneyLaunderingRiskType.find(rt => rt.value === value);
    return riskType ? riskType.label : '';
  };


  onRowClick(event: IRowSelectionEventArgs) {
    this.selectedCase = event.added[0];  // event.added[0] contains the row data
  }


  openCase() {
    if (!this.selectedCase) return;

    if (this.caseRights === 'NONE') {
      const activity = newActivity({
        message: `Ingen tilgang på området "Saksbehandling".`,
        type: "failure",
        timestamp: new Date()
      });

      this.als.appendActivity(activity);
      return;
    }

    if (this.caseRights === 'OWN_CASES') {
      this.dlxClient.GetCaseLimited(this.selectedCase.CaseId).subscribe({
        next: (response) => {
          if (this.rights.checkCaseAccessOwnCases({ sa: response.CaseResponsibleId!, sb: response.CaseHandlerId }, this.sys.loggedinUserContact.Id)) {
            this.router.navigate(['/case', response.Id]);
          } else {
            const activity = newActivity({
              message: `Ingen tilgang, du har kun tilgang til egne saker.`,
              type: "failure",
              timestamp: new Date()
            });

            this.als.appendActivity(activity);
            alert("Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.");
          }
          this.selectedCase = null;
        },
        error: () => {
          this.selectedCase = null;
        }
      });


      return;
    }

    this.router.navigate(['/case', this.selectedCase.CaseId]);
    this.selectedCase = null;
  }

  showFormDialog = false;
  openMoneyLaunderingDialog() {
    console.log('Selected case contact:', this.selectedCaseContact);

    if (this.selectedCaseContact) {
      this.dlxClient.GetMoneyLaunderingByCaseContactId(this.selectedCaseContact).subscribe({
        next: (res) => {
          console.log(res)
          this.moneyLaunderingDialog.open();
          console.log();

          this.moneyLaunderingDialog.opened.subscribe({
            next: () => {
              this.showFormDialog = true;
              // this.form.datasource = res;
              this.form.init(res);
            }
          })

        },
        error: (err) => { }
      })

    }
  }




  resetFormState() {
    // this.moneyLaunderingFormComponent.resetForm();
  }

  onFormSaved(savedData: any) {
    // Handle the data returned from the form submission
    console.log('Form data saved:', savedData);

    // Optionally update the grid or take any other necessary actions here
    this.moneyLaunderingDialog.close();

    this.dlxClient.GetInvoiceData
  }

  submitEnabled() {
    return false
  }
}
