<form [formGroup]="moneyLaunderingForm" (ngSubmit)="onSubmit()">
  <div class="toolbar">
    <div class="toolbar-item simple-select-wrapper">
      <label igxLabel class="simple-select-label simple-select-label--selected" for="controlType">Kontrolltype</label>
      <igx-simple-combo formControlName="controlType" [data]="controlTypeOptions" displayDensity="compact"
        [type]="'border'" [displayKey]="'Name'" [valueKey]="'Code'" (selectionChanging)="onControlTypeChange($event)">
      </igx-simple-combo>
    </div>
    <!-- @if(moneyLaunderingForm.get('controlType')?.value !== 'Fo') { -->
    <app-toggle-switch [labels]="['Vis aktuelle', 'Vis alle']" (toggleStatus)="toggleShowAllFields($event)">
    </app-toggle-switch>
    <!-- } -->


    <div class="toolbar-item">
      <label for="sistEndre">Sist endret</label>
      <input id="sistEndre" type="text" [value]="lastEditedDate | date: 'dd.MM.yyyy HH:mm'" readonly />
      <input id="initials" type="text" [value]="editorInitials" readonly
        style="border: none; background: transparent; font-weight: bold;" />
    </div>

  </div>

  <div *ngIf="!showAllFields || moneyLaunderingForm.controls['fullName'].enabled">
    <label for="fullName">Fullt navn</label>
    <input id="fullName" formControlName="fullName" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['dateOfBirth'].enabled">
    <label for="dateOfBirth">Fødselsdato</label>
    <input id="dateOfBirth" formControlName="dateOfBirth" type="date">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['nationalIdentityNumber'].enabled">
    <label for="nationalIdentityNumber">Fødselsnummer</label>
    <input id="nationalIdentityNumber" formControlName="nationalIdentityNumber" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['birthPlace'].enabled">
    <label for="birthPlace">Fødselssted</label>
    <input id="birthPlace" formControlName="birthPlace" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['gender'].enabled">
    <label for="gender">Kjønn (hvis klient ikke har norsk fødselsnummer)</label>
    <input id="gender" formControlName="gender" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['citizenship'].enabled">
    <label for="citizenship">Statsborgerskap</label>
    <input id="citizenship" formControlName="citizenship" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['secondaryCitizenship'].enabled">
    <label for="secondaryCitizenship">Statsborgerskap 2 (hvis klient ikke har norsk fødselsnummer)</label>
    <input id="secondaryCitizenship" formControlName="secondaryCitizenship" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['organizationNumber'].enabled">
    <label for="organizationNumber">Organisasjonsnummer</label>
    <input id="organizationNumber" formControlName="organizationNumber" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['dNumber'].enabled">
    <label for="dNumber">D-nummer</label>
    <input id="dNumber" formControlName="dNumber" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['otherUniqueId'].enabled">
    <label for="otherUniqueId">Annen entydig identitetskode</label>
    <input id="otherUniqueId" formControlName="otherUniqueId" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['address'].enabled">
    <label for="address">Fast adresse (hvis klient ikke har norsk fødselsnummer)</label>
    <input id="address" formControlName="address" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['credentials'].enabled">
    <label for="credentials">Legitimasjon (dokumentasjon av identitet)</label>
    <input id="credentials" formControlName="credentials" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['legalForm'].enabled">
    <label for="legalForm">Organisasjonsform (jur.personer ikke reg.i off.reg)</label>
    <input id="legalForm" formControlName="legalForm" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['foundationDate'].enabled">
    <label for="foundationDate">Stiftelsestidspunkt (jur.personer ikke reg.i off.reg)</label>
    <input id="foundationDate" formControlName="foundationDate" type="date">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['chiefExecutiveOfficer'].enabled">
    <label for="chiefExecutiveOfficer">Daglig leder (jur.personer ikke reg.i off.reg)</label>
    <input id="chiefExecutiveOfficer" formControlName="chiefExecutiveOfficer" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['holder'].enabled">
    <label for="holder">Innehaver (jur.personer ikke reg.i off.reg)</label>
    <input id="holder" formControlName="holder" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['clientsContactPerson'].enabled">
    <label for="clientsContactPerson">Kontaktpersons navn (hentes automatisk fra eget kort)</label>
    <input id="clientsContactPerson" formControlName="clientsContactPerson" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['otherRelevantDoc'].enabled">
    <label for="otherRelevantDoc">Annen relevant dokumentasjon (aksjeeierbok, aksjonæravtaler,
      organisasjonsstruktur)</label>
    <input id="otherRelevantDoc" formControlName="otherRelevantDoc" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['officialRegDoc'].enabled">
    <label for="officialRegDoc">Dokumentasjon fra offentlige registre (for å kontrollere reelle
      rettighetshavere)</label>
    <input id="officialRegDoc" formControlName="officialRegDoc" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['control'].enabled">
    <label for="control">Kontroll av innhentede opplysninger (næringslivsdatabaser)</label>
    <input id="control" formControlName="control" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['transactionOnBehalfOf'].enabled">
    <label for="transactionOnBehalfOf">Utføres transaksjonen på vegne av andre fysiske personer (og i så fall hvem er
      dette?)</label>
    <input id="transactionOnBehalfOf" formControlName="transactionOnBehalfOf" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['pep'].enabled">
    <label for="pep">Politisk eksponert person (PEP)</label>
    <input id="pep" formControlName="pep" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['legalPersons'].enabled">
    <label for="legalPersons">Juridiske personer: Utdypende opplysninger (om klientens virksomhets art og
      omfang)</label>
    <input id="legalPersons" formControlName="legalPersons" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['originOfClientFunds'].enabled">
    <label for="originOfClientFunds">Opprinnelse til klientens midler (og dokumentasjon av dette)</label>
    <input id="originOfClientFunds" formControlName="originOfClientFunds" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['anonymousTransactions'].enabled">
    <label for="anonymousTransactions">Anonyme transaksjoner (informasjon om dette)</label>
    <input id="anonymousTransactions" formControlName="anonymousTransactions" type="text">
  </div>

  <div *ngIf="showAllFields || moneyLaunderingForm.controls['implementedMeasures'].enabled">
    <label for="implementedMeasures">Iverksatte tiltak (for å unngå straffbare handlinger)</label>
    <input id="implementedMeasures" formControlName="implementedMeasures" type="text">
  </div>

  <button type="submit" [disabled]="moneyLaunderingForm.invalid">Legg til</button>
</form>