import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IgxSimpleComboModule, IgxSwitchComponent, IgxSwitchModule } from '@infragistics/igniteui-angular';
import { MoneyLaunderingRiskType } from 'src/app/util/MoneyLaunderingRiskType';
import { DatalexClient, IMoneyLaunderingBE, IMoneyLaunderingTypeBE } from '@datalex-software-as/datalex-client';
import { ToggleSwitchComponent } from "../controls/toggle-switch/toggle-switch.component";

@Component({
  selector: 'app-money-laundering-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IgxSimpleComboModule, IgxSwitchModule, FormsModule, ToggleSwitchComponent],
  templateUrl: './money-laundering-form.component.html',
  styleUrls: ['./money-laundering-form.component.scss']
})
export class MoneyLaunderingFormComponent implements OnInit {
  moneyLaunderingForm: FormGroup;

  @Input() formData: any; // This will be the selected case contact or similar object
  _datasource!: IMoneyLaunderingBE;
  @Input() set datasource(val: IMoneyLaunderingBE) { this._datasource = val; console.log(this._datasource) }
  get datasource(): IMoneyLaunderingBE { return this._datasource; }
  @Output() formSaved = new EventEmitter<any>();

  isDisabled: boolean = true;

  riskEvaluationOptions = MoneyLaunderingRiskType.filter(option => option.value !== -1);
  lastEditedDate: Date | null = null;
  editorInitials: string = '';
  showAllFields: boolean = false;

  controlTypeOptions: IMoneyLaunderingTypeBE[] = [];

  requiredFieldsMap: { [key: string]: string[] } = {
    Fe: [
      'conclusion', 'transactions', 'fullName', 'dNumber', 'otherUniqueId', 'address', 'otherInfo'
    ],
    No: [
      'conclusion', 'transactions', 'fullName', 'dNumber', 'otherUniqueId', 'address', 'otherInfo',
      'dateOfBirth', 'nationalIdentityNumber', 'birthPlace', 'gender', 'citizenship', 'secondaryCitizenship',
      'organizationNumber', 'credentials', 'legalForm', 'foundationDate', 'chiefExecutiveOfficer', 'holder',
      'clientsContactPerson', 'otherRelevantDoc', 'officialRegDoc', 'control', 'transactionOnBehalfOf', 'pep'
    ],
    Fo: []
  };

  constructor(private dlxClient: DatalexClient, private cdr: ChangeDetectorRef) {
    this.moneyLaunderingForm = new FormGroup({
      controlType: new FormControl(null, Validators.required),
      conclusion: new FormControl('', Validators.required),
      transactions: new FormControl(''),
      fullName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(''),
      nationalIdentityNumber: new FormControl(''),
      birthPlace: new FormControl(''),
      gender: new FormControl(''),
      citizenship: new FormControl(''),
      secondaryCitizenship: new FormControl(''),
      organizationNumber: new FormControl(''),
      dNumber: new FormControl(''),
      otherUniqueId: new FormControl(''),
      address: new FormControl(''),
      credentials: new FormControl(''),
      legalForm: new FormControl(''),
      foundationDate: new FormControl(''),
      chiefExecutiveOfficer: new FormControl(''),
      holder: new FormControl(''),
      clientsContactPerson: new FormControl(''),
      otherRelevantDoc: new FormControl(''),
      officialRegDoc: new FormControl(''),
      control: new FormControl(''),
      transactionOnBehalfOf: new FormControl(''),
      pep: new FormControl(''),
      otherInfo: new FormControl(''),
      legalPersons: new FormControl(''),
      originOfClientFunds: new FormControl(''),
      anonymousTransactions: new FormControl(''),
      implementedMeasures: new FormControl('')
    });
  }

  ngOnInit(): void {
    console.log(this.datasource);

    this.loadControlTypes();
    this.loadLastEditedData();

  }


  onControlTypeChange(event: any): void {
    const controlTypeCode = event.newSelection?.Code;
    console.log(controlTypeCode);
    const requiredFields = this.requiredFieldsMap[controlTypeCode] || [];

    Object.keys(this.moneyLaunderingForm.controls).forEach((key) => {
      const control = this.moneyLaunderingForm.get(key);

      if (control) {
        if (key === 'controlType') {
          control.enable();
        } else if (controlTypeCode === 'Fo' || requiredFields.includes(key)) {
          control.setValidators(Validators.required);
          control.enable();
        } else {
          control.clearValidators();
          control.disable();
        }
        control.updateValueAndValidity();
      }
    });

    // No need to reset the switch state here
  }


  toggleShowAllFields(status: boolean): void {
    console.log("status", status);

    this.showAllFields = !status;

    const controlType = this.moneyLaunderingForm.get('controlType')?.value;
    const requiredFields = this.requiredFieldsMap[controlType] || [];

    Object.keys(this.moneyLaunderingForm.controls).forEach((key) => {
      const control = this.moneyLaunderingForm.get(key);

      if (control) {
        if (key === 'controlType') {
          control.enable();
        } else if (!this.showAllFields && !requiredFields.includes(key)) {
          control.disable();
        } else if (this.showAllFields) {
          control.enable();
          control.markAsTouched();
        }
      }
    });
  }


  init(data: IMoneyLaunderingBE) {
    console.log('test');
    this.datasource = data;
    console.log(this.datasource);

    this.loadControlTypes();
    this.loadLastEditedData();


  }

  resetForm() {
    this.moneyLaunderingForm.reset();
    this.moneyLaunderingForm.controls['controlType'].setValue(this.controlTypeOptions.find(ct => ct.Code === 'Fe'));
    this.showAllFields = false;
  }


  loadControlTypes() {
    this.dlxClient.GetMoneyLaunderingTypeAll().subscribe({
      next: (res) => {
        this.controlTypeOptions = res;
        const defaultControlType = this.controlTypeOptions.find(option => option.Code === 'Fe');
        if (defaultControlType) {
          this.moneyLaunderingForm.get('controlType')?.setValue(defaultControlType.Code);
          this.onControlTypeChange({ newSelection: defaultControlType });
        }
        console.log(res);

      }
    });
  }

  loadLastEditedData() {
    this.lastEditedDate = new Date(); // Fetch actual data from backend
    this.editorInitials = 'TL'; // Fetch actual initials from backend
  }

  onSubmit() {
    if (this.moneyLaunderingForm.valid) {
      this.formSaved.emit(this.moneyLaunderingForm.value);
    }
  }

  ngOnChanges() {
    if (this.formData) {
      this.moneyLaunderingForm.patchValue(this.formData);
    }
  }

  printSection() { }
  onExport() { }
}
