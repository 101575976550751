<form class="status-laundering-form" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <div class="div1 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Klientansvarlig</label>
    <igx-simple-combo formControlName="clientResponsible" [data]="employees" displayDensity="compact" [type]="'border'"
      [displayKey]="'ContactName'" [valueKey]="'ContactId'" (selectionChanging)="combo.searchTypeChanging($event)"
      (opening)="combo.focusInputAndFixedHeight($event, false)">
    </igx-simple-combo>

  </div>

  <div class="div2 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Saksansvarlig</label>
    <igx-simple-combo formControlName="caseResponsible" [data]="employees" displayDensity="compact" [type]="'border'"
      [displayKey]="'ContactName'" [valueKey]="'ContactId'" (selectionChanging)="combo.searchTypeChanging($event)"
      (opening)="combo.focusInputAndFixedHeight($event, false)">
    </igx-simple-combo>
  </div>


  <div class="div3 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Risikovurdering</label>
    <igx-simple-combo formControlName="riskEvaluation" [data]="riskEvaluationOptions" displayDensity="compact"
      [type]="'border'" [displayKey]="'label'" [valueKey]="'value'"
      (selectionChanging)="combo.searchTypeChanging($event)" (opening)="combo.focusInputAndFixedHeight($event, false)">
    </igx-simple-combo>
  </div>

  <div class="div4 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Kontrolltype</label>
    <igx-simple-combo formControlName="controlType" [data]="controlTypeOptions" displayDensity="compact"
      [type]="'border'" [displayKey]="'Name'" [valueKey]="'Id'" (selectionChanging)="combo.searchTypeChanging($event)"
      (opening)="combo.focusInputAndFixedHeight($event, false)">
    </igx-simple-combo>
  </div>
  <div class="div5 simple-select-wrapper">
    <label igxLabel>Inkommet dato</label>
    <div class="date-picker-container">
      <igx-date-picker formControlName="startDate" [type]="'border'" displayDensity="compact" inputFormat="dd.MM.yyyy"
        displayFormat="dd.MM.yyyy">
      </igx-date-picker>

      <span class="date-separator"> - </span>

      <igx-date-picker formControlName="endDate" [type]="'border'" displayDensity="compact" inputFormat="dd.MM.yyyy"
        displayFormat="dd.MM.yyyy">
      </igx-date-picker>
    </div>
    <div
      *ngIf="filterForm.errors?.['dateRangeInvalid'] && (filterForm.controls.startDate.touched || filterForm.controls.endDate.touched)">
      <small class="error-text">* Sluttdato må være senere enn startdato.</small>
    </div>
  </div>

  <div class="div6 buttons-container">
    <button type="submit" igxButton="raised" [disabled]="filterForm.invalid">Oppdater</button>
    <!-- <button type="button" igxButton="raised" (click)="onPrint()">Skriv ut</button> -->
    <button type="button" igxButton="raised" (click)="printSection()">Skriv ut</button>
    <!-- <button type="button" igxButton="raised"
        (click)="printSection({ data: cases, headers: ['SaksNr', 'KA', 'SA', 'SB', 'Rolle', 'KontaktNr', 'Kontakt', 'Tittel', 'Inkommet', 'Kontrolltype', 'RisikoV', 'Status', 'Konklusjon'], fieldName: ['CaseNumber', 'CaseResponsibleINI', 'CaseWorkerINI', '...', '...'] })">
        Skriv ut gridden
      </button> -->
    <button type="button" igxButton="raised" (click)="onExport()">Excel</button>
  </div>
</form>
<div class="button-row">
  <div class="button-cluster">
    <app-button-row-button label="Åpne sak" icon="folder" (click)="openCase()" [enabled]="!!selectedCaseContact" />
    <app-button-row-button label="Åpne hvitvaskingsskjema" icon="open_in_new" (click)="openMoneyLaunderingDialog()"
      [enabled]="!!selectedCaseContact" />
  </div>

</div>

<iframe id="printFrame" style="display:none;">

</iframe>

<igx-grid #grid id="gridSection" igxPreventDocumentScroll [data]="cases" [isLoading]="isLoading"
  [displayDensity]="deviceService.gridDensity" height="100%" [emptyGridMessage]="'Ingen data funnet'"
  emptyFilteredGridMessage="Filtrering gir ingen treff" [autoGenerate]="false" width="100%" [cellSelection]="'none'"
  [rowSelection]="'single'" (rowSelectionChanging)="handleRowSelection($event)" [hideRowSelectors]="true"
  [allowFiltering]="true">

  <igx-column width="60px" field="CaseNumber" header="S.Nr" [sortable]="true" [dataType]="'number'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column width="50px" field="ClientResponsibleINI" header="KA" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column width="50px" field="CaseResponsibleINI" header="SA" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column width="50px" field="CaseWorkerINI" header="SB" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <!-- <igx-column width="50px" field="" header="HF" [sortable]="true" [dataType]="'string'"
            [filterCellTemplate]="defaultFilterTemplate"></igx-column> -->

  <igx-column width="160px" field="RoleTypeName" header="Rolle" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column width="60px" field="ContactNumber" header="K.Nr" [sortable]="true" [dataType]="'number'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column width="150px" field="ContactName" header="Kontakt" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column field="CaseTitle" header="Tittel" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate">
  </igx-column>

  <igx-column width="100px" field="StartedDate" header="Inkommet" [sortable]="true" [dataType]="'date'"
    [formatter]="dateFormatter" [filterCellTemplate]="defaultFilterTemplate">
  </igx-column>

  <igx-column width="140px" field="MoneyLaunderingTypeName" header="Kontrolltype" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate">
  </igx-column>

  <igx-column width="100px" field="RiskType" header="RisikoV" [sortable]="true" [dataType]="'string'"
    [formatter]="riskTypeFormatter" [filterCellTemplate]="defaultFilterTemplate">
  </igx-column>


  <igx-column width="70px" field="CaseStatusTypeName" header="Status" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate">
  </igx-column>

  <igx-column field="Conclusion" header="Konklusjon" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
</igx-grid>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
</ng-template>



<igx-dialog #moneyLaunderingDialog title="Hvitvaskingsskjema" closeOnOutsideSelect="false" (onClose)="resetFormState()"
  leftTopLabel="OK" (leftBottomSelect)="moneyLaunderingDialog.close()">
  @if(true) {
  <app-money-laundering-form #moneylaunderingForm [formData]="''" (formSaved)="onFormSaved($event)" />
  } @else {}
  <div class="flex-row btn-section">
    <!-- <button type="submit" igxButton="raised" igxButtonColor="white"
      [style.background]=" submitEnabled() ? '#AEC965' : '#E0E0E0'" igxRipple="white" [disabled]="!submitEnabled()"> -->
    <button type="submit" igxButton="raised" igxButtonColor="white"
      [style.background]=" submitEnabled() ? '#AEC965' : '#E0E0E0'" igxRipple="white" [disabled]="!submitEnabled()">
      LEGG TIL
    </button>

    <button igxButton="raised" igxButtonColor="white" [style.background]="'#575757'" igxRipple="white" type="button"
      (click)="moneyLaunderingDialog.close(); ">
      AVBRYT
    </button>
  </div>
</igx-dialog>